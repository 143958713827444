import { LinkContainer } from "react-router-bootstrap";
import { PlaceOrder } from "../../components/HomeComponents/PlaceOrderComponent/PlaceOrder";
import WeOffer from "../../components/HomeComponents/WeOffer/WeOffer";
import WhyUs from "../../components/HomeComponents/WhyUs/WhyUs";
import { t } from "i18next";
import '../../components/HomeComponents/Banner/Banner.css'
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import img from '../../data/1.png'
import { environment } from "../../envirement";
import Montreal from "../../assets/blog/3.png"
import ottawa from "../../assets/blog/9.png"
import toronto from "../../assets/blog/8.png"
import vancouver from "../../assets/blog/7.png"
import new_york from "../../assets/blog/14.png"
import las_vegas from "../../assets/blog/1.png"
import chicago from "../../assets/blog/2.png"
import atlanta from "../../assets/blog/5.png"
import florida from "../../assets/blog/6.png"
import miami from "../../assets/blog/8.png"
import washington from "../../assets/blog/10.png"
import san_diego from "../../assets/blog/11.png"
import philadelphia from "../../assets/blog/12.png"
import california from "../../assets/blog/13.png"
import boston from "../../assets/blog/15.png"
import los_angeles from "../../assets/blog/16.png"
import monaco from "../../assets/blog/17.png"
import paris from "../../assets/blog/4.png"
import berlin from "../../assets/blog/18.png"
import sydney from "../../assets/blog/19.png"
import { useEffect } from "react";
import Footer from "../../components/Footer/Footer";
import Testimonials from "../../components/Testimonials/Testimonials";
import { Helmet } from "react-helmet";
const cityImages = {
  montreal: Montreal,
  ottawa: ottawa,
  toronto: toronto,
  vancouver: vancouver,
  "new york": new_york,
  "las vegas": las_vegas,
  chicago: chicago,
  atlanta: atlanta,
  florida: florida,
  miami: miami,
  washington: washington,
  "san diego": san_diego,
  philadelphia: philadelphia,
  california: california,
  boston: boston,
  "los angeles": los_angeles,
  monaco: monaco,
  paris: paris,
  berlin: berlin,
  sydney: sydney,
};

const cityDescriptions = {
  Montreal: "Meryashome offers virtual staging services in Montreal, combining historic charm with modern aesthetics. Showcase your property with realistic 3D renderings and attract a wide range of buyers.",
  Ottawa: "Discover virtual staging services in Ottawa with Meryashome. Tailored 3D renderings that highlight your property’s potential and attract buyers across the city.",
  Toronto: "Meryashome’s virtual staging in Toronto helps property listings shine. Transform your space with modern 3D renderings that appeal to a diverse Toronto market.",
  Vancouver: "Enhance your property listing with Meryashome’s virtual staging services in Vancouver. Realistic 3D renderings designed for Vancouver’s dynamic real estate market.",
  NewYork: "Meryashome brings virtual staging to New York with cutting-edge 3D renderings, making your property listings stand out in one of the world’s most competitive markets.",
  LasVegas: "Virtual staging in Las Vegas with Meryashome: Stunning 3D renderings that make your property stand out in the vibrant Las Vegas real estate scene.",
  Chicago: "Transform your Chicago property listings with Meryashome’s virtual staging services. Custom 3D renderings that appeal to Chicago’s diverse buyer base.",
  Atlanta: "Meryashome’s virtual staging services in Atlanta showcase properties with realistic 3D renderings that capture the attention of Atlanta’s growing real estate market.",
  Florida: "Virtual staging in Florida with Meryashome. High-quality 3D renderings that highlight the potential of your property for Florida’s competitive real estate market.",
  Miami: "Transform your Miami property listings with Meryashome’s virtual staging services. Showcase your property with 3D renderings tailored to Miami’s vibrant market.",
  Washington: "Meryashome offers virtual staging services in Washington, D.C., with realistic 3D renderings designed to make your property listings stand out.",
  SanDiego: "Showcase your property in San Diego with Meryashome’s virtual staging services. Custom 3D renderings tailored to the San Diego real estate market.",
  Philadelphia: "Virtual staging in Philadelphia with Meryashome: Stunning 3D renderings that help your property stand out in Philadelphia’s real estate market.",
  California: "Meryashome’s virtual staging services in California bring your property listings to life with realistic 3D renderings, designed to appeal to California buyers.",
  Boston: "Enhance your property listings with Meryashome’s virtual staging services in Boston. Transform your space with realistic 3D renderings that appeal to Boston’s buyers.",
  LosAngeles: "Virtual staging in Los Angeles with Meryashome: Stunning 3D renderings that showcase your property’s potential in the LA real estate market.",
  Monaco: "Meryashome’s virtual staging services in Monaco offer high-quality 3D renderings that showcase your property’s potential and appeal to Monaco’s luxury market.",
  Paris: "Virtual staging services in Paris by Meryashome. Showcase your property with stunning 3D renderings that reflect Parisian charm and modern appeal.",
  Berlin: "Meryashome brings virtual staging to Berlin with tailored 3D renderings designed to attract buyers in Berlin’s diverse real estate market.",
  Sydney: "Meryashome’s virtual staging services in Sydney offer realistic 3D renderings that highlight your property’s potential, making it stand out in Sydney’s competitive market.",
};


const Locations = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { cityName } = useParams();
console.log(cityName)
useEffect(() => {
  window.scrollTo(0, 0);
}, [cityName]);
  // Format cityName to match the cityImages keys
  const formattedCityName = cityName.toLowerCase();

  const handleOrderClick = () => {
    localStorage.setItem("serviceURL", "placeOrder");
  };
  const cityImage = cityImages[cityName];
  const cityDescription = cityDescriptions[cityName];
  return (
    <>
 <Helmet>
      <title>{cityName} - Meryas Home</title>
      <meta name="description" content={cityDescription} />
      <meta
        name="keywords"
        content={`${cityName} Virtual Staging, Real Estate Staging ${cityName}, 3D Rendering ${cityName}, Property Staging ${cityName}, Home Staging ${cityName}, Virtual Home Staging, ${cityName} Real Estate, Modern Home Staging, Virtual Renovation Staging`}
      />
      <meta name="robots" content="index, follow" />
      <meta property="og:title" content={`Virtual Staging Services in ${cityName} - Meryashome`} />
      <meta property="og:description" content={cityDescription} />
      <meta property="og:image" content={cityImage} />
      <meta property="og:url" content={`https://www.meryashome.com/cities/${cityName.toLowerCase()}`} />
      <meta property="og:type" content="website" />
      <meta property="og:locale" content="en_CA" />
    </Helmet>
 
      <div className="bg-[#f4f5f7] px-8 py-16 flex flex-col items-center gap-12 lg:flex-row-reverse">
        {/* Left Section: Text Content */}
        <div className="lg:flex-1 flex justify-center lg:items-stretch items-center flex-col lg:flex-row mt-8 lg:mt-0 w-full lg:w-auto lg:h-screen">
          <h1 className="lg:hidden text-3xl md:text-4xl lg:text-5xl font-bold text-[#22262e] mb-6 text-center">
            {t(`locations.${cityName}.title`)}
          </h1>
          <img
            src={cityImages[formattedCityName] || img} // Fallback to a default image if not found
            alt={`${cityName} Real Estate Staging`}
            className="rounded-lg object-cover w-11/12 sm:w-3/4 lg:w-full max-w-2xl shadow-lg"
          />
        </div>
        <div className="lg:flex-1 text-center lg:text-left lg:h-screen lg:flex lg:flex-col lg:justify-between">
          <div>
            <h1 className="hidden lg:block text-3xl md:text-4xl lg:text-5xl font-bold text-[#22262e] mb-6 text-start mt-36">
              {t(`locations.${cityName}.title`)}
            </h1>
            <div className="border max-w-[940px] w-full m-0 !border-[#06466366]" />
          </div>
          <p className="text-[#777e90] text-base md:text-lg mb-8 text-justify">
            {t(`locations.${cityName}.text`)}
          </p>
          <button
            onClick={() => navigate("/prices")}
            className="bg-[#ecb365] text-white py-3 px-6 rounded-lg font-semibold hover:bg-[#edc57a] transition w-full"
          >
            {t(`locations.btn`)}
          </button>
        </div>
      </div>

      {/* Additional Components */}
      <div className="banner-header bg-[#041c32]">
        <h1 className="banner-header-title">{t("home.banner.title")}</h1>
        <LinkContainer to={"/prices"}>
          <button className="banner-header-btn" onClick={handleOrderClick}>
            Order Now
          </button>
        </LinkContainer>
      </div>
      <WeOffer />
      <PlaceOrder />
      <WhyUs />
      <Testimonials/>
      <Footer/>
    </>
  );
};

export default Locations;