import React from "react";
import Partners from "../../components/HomeComponents/Partners/Partners";
import Banner from "../../components/HomeComponents/Banner/Banner";
import Services from "../../components/HomeComponents/Services/Services";
import WeOffer from "../../components/HomeComponents/WeOffer/WeOffer";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import WhyUs from "../../components/HomeComponents/WhyUs/WhyUs";
import StagedListing from "../../components/HomeComponents/StagedListing/StagedListing";
import { PlaceOrder } from "../../components/HomeComponents/PlaceOrderComponent/PlaceOrder";
import Testimonials from "../../components/Testimonials/Testimonials";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import ServicesPage from "../Services/ServicesPage";
import Steps from "../Steps";
import StepSignup from "../../components/SignUpSteps";
import RandomBlogCardList from "../Blogs/RandomBlogCardList";




const Home = () => {
  
  useEffect(() => {
    window.scrollTo(0, 0);
    localStorage.setItem("serviceURL", "");

  }, []);

  return (
    <div className="bg">
<Helmet>
  {/* Page Title */}
  <title>Home | Meryas Home - Virtual Staging & Real Estate Solutions</title>
  {/* Meta Description */}
  <meta
    name="description"
    content="Discover Meryas Home’s professional virtual staging, 3D modeling, photo editing, and real estate enhancement services. Elevate your property listings with high-quality visuals, rapid 24-hour turnaround, and expert design solutions."
  />
  {/* Keywords */}
  <meta
    name="keywords"
    content="virtual staging, 3D modeling, virtual renovation, real estate enhancement, photo editing, item removal, image enhancement, 2d textured plan, property marketing services"
  />
  {/* Open Graph Meta Tags for Social Sharing */}
  <meta property="og:title" content="Home | Meryas Home - Virtual Staging & Real Estate Solutions" />
  <meta
    property="og:description"
    content="Elevate your real estate listings with Meryas Home’s virtual staging, 3D rendering, and renovation services. Achieve captivating property visuals with our expert team."
  />
  <meta property="og:image" content="https://www.meryashome.com/static/media/2.a1f563e653ae72060cb2.png" />
  <meta property="og:url" content="https://www.meryashome.com/" />
  <meta property="og:type" content="website" />
  <meta property="og:site_name" content="Meryas Home" />
  {/* Twitter Meta Tags for Social Sharing */}
  <meta name="twitter:card" content="summary_large_image" />
  <meta name="twitter:title" content="Home | Meryas Home - Virtual Staging & Real Estate Solutions" />
  <meta
    name="twitter:description"
    content="Transform real estate visuals with Meryas Home’s expert virtual staging, 3D modeling, and photo editing services. Fast 24-hour turnaround to enhance property appeal."
  />
  <meta name="twitter:image" content="https://www.meryashome.com/static/media/2.a1f563e653ae72060cb2.png" />
  <meta name="twitter:site" content="@MeryasHome" />
  {/* Canonical Link */}
  <link rel="canonical" href="https://www.meryashome.com/" />
</Helmet>


      
      <Banner />
      <WhyUs />
      <StagedListing />
      <WeOffer />
      <PlaceOrder />
      <ServicesPage />
      <Steps/>
      <StepSignup/>
      <Partners />
      <PlaceOrder />
     <Testimonials />
     <RandomBlogCardList/>
      <Footer />
    </div>
  );
};

export default Home;
