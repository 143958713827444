// AboutPage.jsx
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import Footer from "../components/Footer/Footer";
import { useTranslation } from "react-i18next";
import vsimg from '../assets/img/20 - VS_Meryas.png'
import vr from '../assets/img/RV 4_Meryas.png'
import dint from '../assets/img/3dRendering/3D 1_Meryas.png'
import dext from '../assets/img/3dRendering/3D 6_Meryas.png'
import ie from '../assets/img/Salon _ Salle à manger 3 1 (2).png'
import ir from '../assets/img/Salon _ Salle à manger 3 1 (4).png'
import dtd from '../assets/img/DtD 2-2_Meryas.jpg'
import dp from '../assets/img/6d0bec1d55b38ccb732222a11a9ef573.jpg'
import pm from '../assets/img/WebB-A14.png'
import WhyUs from "../components/HomeComponents/WhyUs/WhyUs";
import Steps from "./Steps";
import StepSignup from "../components/SignUpSteps";
import Partners from "../components/HomeComponents/Partners/Partners";
import { PlaceOrder } from "../components/HomeComponents/PlaceOrderComponent/PlaceOrder";
import Testimonials from "../components/Testimonials/Testimonials";
const About = () => {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="bg-gray-50 font-sans ">
<Helmet>
  <title>About | Meryas Home</title>
  <meta name="description" content="Explore Meryas Home’s innovative solutions in virtual staging, 3D modeling, image enhancement, and digital renovations for real estate. Empower your property listings with photorealistic visuals and immersive virtual experiences." />
  <meta 
    name="keywords" 
    content="Meryas Home, virtual staging, real estate visualization, 3D modeling, virtual renovations, image enhancement, property marketing, photorealistic staging, 2D floor plans, day to dusk, photo editing, property enhancement, digital architecture, home staging, interior and exterior modeling" 
  />
</Helmet>

      {/* Introduction Section */}
      <section className="py-20 px-6 md:px-12 lg:px-24 text-center bg-white">
        <h1 className="text-4xl font-bold text-gray-900">
          <span className="text-[#144d83] ">Meryas Home </span>
          {t("aboutPage.introductionTitle")}
        </h1>
        <p className="mt-4 text-gray-600 text-lg max-w-xl mx-auto">
          {t("aboutPage.introductionDescription")}
        </p>
      </section>

      {/* Our Vision Section */}
      <section className="py-16 px-6 md:px-12 lg:px-24 text-center gradian-section">
        <h2 className="text-4xl font-bold text-gray-800">
          {t("aboutPage.aboutUsTitle")}
        </h2>
        <p className="mt-4 text-gray-600 font-bold max-w-4xl mx-auto">
          {t("aboutPage.aboutUsDescription")}
        </p>
      </section>

      <section className="py-16 px-6 md:px-12 lg:px-24 text-center ">
        <h2 className="text-4xl font-bold text-gray-800">
          {t("aboutPage.visionTitle")}
        </h2>
        <p className="mt-4 text-gray-600 font-bold max-w-4xl mx-auto">
          {t("aboutPage.visionDescription")}
        </p>
      </section>

      {/* Services Section */}
      <section className="py-16 px-6 md:px-12 lg:px-24 bg-gray-100">
        <h3 className="text-4xl font-bold text-center text-gray-800 mb-20">
          {t("aboutPage.servicesTitle")}
        </h3>
        <div className="space-y-12">
          {services.map((service, index) => (
            <div
              key={index}
              className={`a flex flex-col md:flex-row items-center gap-8 md:gap-28 ${
                index % 2 === 1 ? "md:flex-row-reverse" : ""
              }`}
            >
              <div className="flex-shrink-0 w-full md:w-1/2 ">
                <img
                  src={service.image}
                  alt={t(`services.${index}.title`)}
                  className="w-full h-full object-cover rounded-lg shadow-md"
                />
              </div>
              <div className="flex  flex-col w-full md:w-1/2 text-start md:text-left ">
                <h4 className="text-3xl font-bold text-gray-800 mb-2 ">
                  {t(`services.${index}.title`)}
                </h4>
                <p className="text-gray-700 text-lg">
                  {t(`services.${index}.description`)}
                </p>
              </div>
            </div>
          ))}
        </div>
      </section>

      {/* Commitment Section */}
      <section className="py-16 px-6 md:px-12 lg:px-24 text-center gradian-section">
        <h3 className="text-4xl font-bold text-gray-800">
          {t("aboutPage.commitmentTitle")}
        </h3>
        <p className="mt-4 text-gray-600 font-bold max-w-4xl mx-auto">
          {t("aboutPage.commitmentDescription")}
        </p>
      </section>
      <WhyUs/>
    <Steps/>
    <StepSignup/>
    <Partners />
    <PlaceOrder />
    <Testimonials />
      <Footer />
    </div>
  );
};


const services = [
  {
    title: "Virtual Staging",
    description:
      "Transform empty spaces into beautifully furnished rooms to help potential buyers imagine themselves in the property. Our virtual staging process includes designing the layout, selecting modern furniture styles, and adding decor elements. With this service, you can increase the appeal of any empty property and attract more interested buyers by showcasing its true potential.",
    image:
    vsimg,
  },
  {
    title: "Virtual Renovation",
    description:
      "Explore renovation possibilities digitally, allowing clients to see a full preview of potential upgrades before investing. From kitchen makeovers to complete home transformations, our virtual renovation service provides a risk-free way to experiment with design ideas. This service helps property owners and real estate professionals envision renovations without the cost or commitment of actual construction.",
    image:
      vr,
  },
  {
    title: "3D Modeling (Interior)",
    description:
      "Bring designs to life with detailed 3D models that capture every architectural and stylistic detail. Whether it's an interior layout or an entire building exterior, our 3D modeling service enables you to present properties in a way that is both visually stunning and informative. This service is perfect for new developments, renovations, or showcasing specific design elements.",
    image:
      dint,
  }
  ,
  {
    title: "3D Modeling (Exterior)",
    description:
      "Bring designs to life with detailed 3D models that capture every architectural and stylistic detail. Whether it's an interior layout or an entire building exterior, our 3D modeling service enables you to present properties in a way that is both visually stunning and informative. This service is perfect for new developments, renovations, or showcasing specific design elements.",
    image:
      dext,
  },
  {
    title: "Image Enhancement",
    description:
      "Elevate photo quality with professional adjustments to lighting, color, and clarity. Our image enhancement service transforms ordinary images into high-quality visuals that capture attention. By optimizing lighting, correcting colors, and sharpening details, we make sure your photos look vibrant and professional, ready for listings or promotional materials.",
    image:
      ie,
  },
  {
    title: "Item Removal",
    description:
      "Remove unwanted items from images to maintain focus on a property’s main features. Our item removal service helps declutter spaces by erasing distractions such as personal belongings, unwanted furniture, or unsightly objects. This clean and refined approach emphasizes the property’s best attributes, making it easier for clients to see the potential of each space.",
    image:
     ir,
  },
  {
    title: "2d textured plan",
    description:
      "Provide clear, easy-to-understand floor plans with engaging color-coding that highlights different areas. Our 2d textured plans offer clients a visual layout that enhances understanding of the property's structure and flow. This service is ideal for both marketing and planning, as it offers a clear overview of the space in an appealing format.",
    image:
     dp,
  },
  {
    title: "Day to Dusk",
    description:
      "Enhance property photos with a day-to-dusk transformation that adds a warm, twilight ambiance. By simulating dusk lighting, this service highlights the cozy, inviting qualities of a property, perfect for listings that benefit from a more dramatic or serene presentation. Our day-to-dusk transformations make any exterior photo stand out with added appeal.",
    image:
      dtd,
  },
  {
    title: "Photo Modification",
    description:
      "Customize images to meet specific marketing needs, creating cohesive and attractive visuals for various platforms. Our photo modification service allows you to adjust images to fit your branding, add text or logos, or enhance specific areas of the photo. This tailored approach ensures that your images align with your marketing goals and stand out in a competitive market.",
    image:
      pm,
  },
];

export default About;
