// import React, { useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
// import USD from '../../assets/img/flags/us.png';
// import EUR from '../../assets/img/flags/eur.png';
// import AUD from '../../assets/img/flags/aus.png';
// import CAD from '../../assets/img/flags/cad.png';
// import GBP from '../../assets/img/flags/ls.png';
// import NZD from '../../assets/img/flags/nzd.png';
// import Other from '../../assets/img/flags/world.png';

// const CurrencySelectionPage = ({ setCurrency }) => {
//   const [selectedCurrency, setSelectedCurrency] = useState("");
//   const navigate = useNavigate();

//   // Mapping currency codes to languages (you can adjust this mapping if needed)
//   const currencyLangMap = {
//     "USD": "en", // United States Dollar -> English
//     "CAD": "en", // Canadian Dollar -> English
//     "AUD": "en", // Australian Dollar -> English
//     "EUR": "fr", // Euro -> French

  
//     "GBP": "en", // British Pound -> English
//     "NZD": "en", // New Zealand Dollar -> English
//     "Other": "en" // Default language for other currencies
//   };

//   const currencyData = [
//     { name: "United States", code: "USD", flag: USD },
//     { name: "Canada", code: "CAD", flag: CAD },
//     { name: "Australia", code: "AUD", flag: AUD },
//     { name: "Eurozone", code: "EUR", flag: EUR },
    
   
//     { name: "United Kingdom", code: "GBP", flag: GBP },
//     { name: "New Zealand", code: "NZD", flag: NZD },
//     { name: "Other", code: "Other", flag: Other }
//   ];

//   useEffect(() => {
//     const savedCurrency = localStorage.getItem("currency");
//     const savedLng = localStorage.getItem("lng");
    
//     if (savedCurrency && savedLng) {
//       setCurrency(savedCurrency);
//       // setLng(savedLng);
//       navigate("/home");
//     }
//   }, [navigate, setCurrency]);

//   const handleCurrencyChange = (currency) => {
//     const language = currencyLangMap[currency] || "en"; // Default to English if not mapped
//     setSelectedCurrency(currency);
//     localStorage.setItem("currency", currency);
//     localStorage.setItem("lng", language); // Set the language in localStorage
//     setCurrency(currency);
//     navigate("/home");
//   };

//   return (
//     <div className="min-h-screen flex flex-col justify-center items-center bg-gray-100 relative">

//       {/* Background */}
//       <div className="absolute inset-0 bg-cover bg-center" style={{ backgroundImage: "url('https://cdn.shopify.com/s/files/1/0795/1083/1380/files/The_impact_of_AI_and_VR_on_3D_architectural_modeling_480x480.jpg?v=1704820143')" }}>
//         <div className="absolute inset-0 bg-[#041C32] opacity-90"></div>
//       </div>
//       <div className="absolute left-0 top-0  w-full h-full">
//         <img className="w-56 "  src={require("../../assets/img/lionLogo.png")}
//                     alt="meryas icon"/>
//         </div>
//       {/* Content */}
//       <div className="relative z-10 text-center flex flex-col items-center">
       
//       <img className="w-56 "  src={require("../../assets/img/map.png")}
//                     alt="meryas icon"/>
//         <h1 className="text-2xl font-semibold text-white mb-8">Choose Your Region</h1>
//         <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 text-center">
//           {currencyData.map(({ name, code, flag }) => (
//             <div key={code} className="space-y-4">
//               <button
//                 className="flex items-center justify-start w-full px-4 py-2 bg-white text-gray-700 text-sm hover:bg-gray-100 hover:text-[#ecb365] transition-all rounded shadow"
//                 onClick={() => handleCurrencyChange(code)}
//               >
//                 <img src={flag} alt={`${name} flag`} className="w-6 h-6 mr-2" />
//                 <span>{name}</span> {/* Display country name */}
//               </button>
//             </div>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default CurrencySelectionPage;

import React from "react";

import SelectCountry from "../../components/SelectCountry";
import { Helmet } from "react-helmet";

const SelectCountryPage = ({ setCurrency }) => {
 

  return (
   <>
    <Helmet>
  {/* Page Title */}
  <title>Meryas Home - Bringing Your Vision to Life with Precision & Elegance</title>
  {/* Meta Description */}
  <meta
    name="description"
    content="Meryas Home brings your vision to life with precision, creativity, and elegance in every virtual detail. Discover our services in the United States, Canada, Australia, Europe, United Kingdom, New Zealand, and beyond."
  />
  {/* Keywords */}
  <meta
    name="keywords"
    content="virtual staging, real estate enhancement, property visualization, 3D modeling, virtual renovation, Meryas Home, United States, Canada, Australia, Europe, United Kingdom, New Zealand"
  />
  {/* Open Graph Meta Tags for Social Sharing */}
  <meta property="og:title" content="Meryas Home - Bringing Your Vision to Life with Precision & Elegance" />
  <meta
    property="og:description"
    content="Explore Meryas Home's professional virtual staging and real estate solutions. We serve clients in the United States, Canada, Australia, Europe, United Kingdom, New Zealand, and beyond."
  />
  <meta property="og:type" content="website" />
  <meta property="og:url" content="https://www.meryashome.com/location" />
  <meta property="og:image" content="https://www.meryashome.com/static/media/2.a1f563e653ae72060cb2.png" />
  <meta property="og:site_name" content="Meryas Home" />
  {/* Twitter Meta Tags for Social Sharing */}
  <meta name="twitter:card" content="summary_large_image" />
  <meta name="twitter:title" content="Meryas Home - Bringing Your Vision to Life with Precision & Elegance" />
  <meta
    name="twitter:description"
    content="Meryas Home delivers precision, creativity, and elegance in virtual staging and property visualization. Serving clients across the globe."
  />
  <meta name="twitter:image" content="https://www.meryashome.com/static/media/2.a1f563e653ae72060cb2.png" />
  <meta name="twitter:site" content="@MeryasHome" />
  {/* Canonical Link */}
  <link rel="canonical" href="https://www.meryashome.com/location" />
</Helmet>
   <SelectCountry  setCurrency={setCurrency} isLandingPage ={ true} />
   </>
  );
};

export default SelectCountryPage;
