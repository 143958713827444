import React, { useEffect } from "react";
import "./ServiceCard.css";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";

const listing = (imgName) => {
  return require(`../../../assets/img/${imgName}`);
};

const ServiceCard = ({ id, title, subtitle, price, image, altImg,currencySymbol }) => {
  const { t } = useTranslation();
  let { serviceId } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [serviceId]);

  const handleClick = () => {
    if (id) {
      localStorage.setItem("serviceURL", `prices/${id}`);
    }else{
      localStorage.setItem("serviceURL", "");
    }
  };

  return (
    <>
      <Link to={`${id}`} onClick={handleClick}>
        <div className="example-card">
          <img className="card-img" src={listing(image)} alt={altImg} />
          <div className="card-info">
            <div className="card-title">{title}</div>
            <div className="card-subtitle">{subtitle}</div>
            <span>{`${price} ${currencySymbol}`}</span>
          </div>
        </div>
      </Link>
    </>
  );
};

export default ServiceCard;
