import React, { useEffect, useState } from "react";
import Services from "../../../../data/services.json";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
  DotGroup
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import { useParams, useNavigate } from "react-router-dom";
import "./ServiceDetails.css";
import { useTranslation } from "react-i18next";
import prevIcon from "../../../../assets/img/icons/prev.svg";
import nextIcon from "../../../../assets/img/icons/next.svg";
import CheckIcon from "../../../../assets/img/icons/check.svg";
import { Helmet } from "react-helmet-async";
import yassine from "../../../../assets/img/yassine.jpg";
import { ChevronLeft, ChevronRight } from "lucide-react";

const ServiceDetails = () => {
  const navigate = useNavigate();
  const { serviceId } = useParams();
  const [path, setPath] = useState(null);
  const [carouselKey, setCarouselKey] = useState(serviceId); // Key for resetting carousel
  const [currentSlide, setCurrentSlide] = useState(0); // Slide index reset
  const { t } = useTranslation();

  const foundService = Services.find((s) => s.name === serviceId);

  useEffect(() => {
    setImagePath(serviceId);
    setCarouselKey(serviceId); // Update key to reset carousel
    setCurrentSlide(0); // Reset to first slide
  }, [serviceId]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (foundService) {
      localStorage.setItem("serviceURL", `prices/${foundService.id}`);
    } else {
      localStorage.setItem("serviceURL", "");
    }
  }, [serviceId, foundService]);

  const setImagePath = (serviceId) => {
    switch (serviceId) {
      case "3D-rendering(interior)":
        setPath("3dRendering");
        break;
      case "3D-rendering(exterior)":
        setPath("3dRendering");
        break;
      case "item-removal":
        setPath("ir_galley");
        break;
      case "day-to-dusk":
        setPath("DayToDust");
        break;
      case "2d-textured-plan":
        setPath("2dPlan");
        break;
      case "virtual-staging":
        setPath("vs");
        break;
      case "virtual-renovation":
        setPath("vr_gallery");
        break;
      case "photo-modification":
        setPath("photomodification");
        break;
      default:
        setPath("");
        break;
    }
  };

  const getImage = (imgName, type) => {
    try {
      console.log(path)
      return require(`../../../../assets/img/${path}/${imgName}`);
    } catch (error) {
      console.error(`Error loading ${type} image:`, error);
      return null;
    }
  };

  return (
    <div className="bf-container">
      {foundService && (
        <>
          <div className="bf-container-header">
            <div className="flex flex-col items-center gap-3">
              <img src={yassine} alt="Yassine Mersni" className="w-[100px] rounded-full" />
              <div>
                <p>“ {t(`services-page.yassine`)}”</p>
                <p>Yassine Mersni <span style={{ fontSize: "15px" }}>(CEO & Founder of Meryas Home)</span></p>
              </div>
            </div>
            <h1 className="text-4xl text-[#ECB365]">
              {t(`services-page.bloc${foundService.id}.title`)}
            </h1>
          </div>

          <div className="bf-secret">
            {[1, 2, 3, 4].map((checkpoint) => (
              <span key={checkpoint}>
                <img src={CheckIcon} alt={`Checked point ${checkpoint}`} />
                <h3>{t(`services-page.bloc${foundService.id}.checkpoints.checkpoint-${checkpoint}`)}</h3>
              </span>
            ))}
          </div>

{path && <CarouselProvider
  key={carouselKey} // Update key to reset on service change
  naturalSlideWidth={100}
  naturalSlideHeight={60}
  totalSlides={foundService?.images.length}
  infinite={false}
  isIntrinsicHeight
  dragEnabled={false}
  touchEnabled={false}
  currentSlide={currentSlide} // Set initial slide
>
  <Slider>
    {foundService?.images.map((image, index) => (
      <Slide index={index} key={index}>
        <div className="vsImages" key={index}>
          {image.imageBefore && image.imageAfter ? (
            <img-comparison-slider
              key={`${serviceId}-${index}`} // Unique key to reset col-resize state
              style={{
                cursor: "col-resize",
                maxWidth: "712px",
                maxHeight: "533px",
                border: "none",
              }}
              className="responsive-slider"
            >
              <img
                slot="first"
                src={getImage(image.imageBefore, "before")}
                style={{
                  objectFit: "contain",
                  width: "auto",
                  height: "100%",
                  maxWidth: "712px",
                }}
                alt={`${image.text} - Before`}
              />
              <img
                slot="second"
                src={getImage(image.imageAfter, "after")}
                style={{
                  objectFit: "contain",
                  width: "auto",
                  height: "100%",
                  maxWidth: "712px",
                }}
                alt={`${image.text} - After`}
              />
            </img-comparison-slider>
          ) : (
            <img
              src={getImage(image.images)}
              style={{
                objectFit: "contain",
                width: "auto",
                height: "100%",
                maxWidth: "712px",
              }}
              alt={image.text}
            />
          )}
          <div className="titleRef">
            {/* Additional info if needed */}
          </div>
        </div>
      </Slide>
    ))}
  </Slider>
  <div className="carousel-controls">
    <ButtonBack className="carousel-arrow left">
      <ChevronLeft size={40} alt="Previous" />
    </ButtonBack>
    <ButtonNext className="carousel-arrow right">
      <ChevronRight size={40} alt="Next" />
    </ButtonNext>
  </div>
  <DotGroup className="carousel-dots" />
</CarouselProvider>}

          <a
            href={`/prices/${foundService.id}`}
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "13px",
            }}
          >
            <button
              style={{
                padding: "9px 30px",
                background: "#ECB365",
                color: "black",
              }}
            >
              {t("header.menu.m9")}
            </button>
          </a>
        </>
      )}
    </div>
  );
};

export default ServiceDetails;
