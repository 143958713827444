import React, { useState, useEffect } from "react";
import "./PlaceOrderPage.css";
import { useTranslation } from "react-i18next";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import ServiceCard from "../../components/PlaceOrder/Cards/ServiceCard";
import Cards from "../../data/cards.json";
import { Helmet } from "react-helmet";
import bannerData from "../../data/banner.json";
import OwlCarousel from "react-owl-carousel";
import currencyData from "../../data/currency.json";

const PlaceOrderPage = ({}) => {
  const currency = localStorage.getItem("currency");
  const { t } = useTranslation();
  const customOptions = {
    loop: true,
    autoplay: true,
    autoplayTimeout: 3000,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 800,
    navText: ["", ""],
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      740: {
        items: 1,
      },
      940: {
        items: 1,
      },
    },
    nav: false,
  };

  const images = (imgName) => {
    return require(`../../assets/img/banner/${imgName}`);
  };

  const lng = localStorage.getItem("lng");

  const getPrice = (id) => {
    if (id) {
      for (const key in currencyData) {
        if (currencyData[key].id === id) {
          const priceData = currencyData[key].price;
          const price = priceData ? priceData[currency] : undefined;
          if (price && price.includes && price.includes("to") && lng === "fr") {
            const newPrice = price.replace("to", "à");
            return newPrice;
          } else {
            return price;
          }
        }
      }
      return null;
    }
  };

  // const getPrice = (id) => {
  //   if (id) {
  //     for (const key in currencyData) {
  //       if (currencyData[key].id === id) {
  //         return currencyData[key].price[currency];
  //       }
  //     }
  //     return null;
  //   }
  // };

  useEffect(() => {
    // Appel de getPrice avec l'ID approprié pour chaque service
    Cards.map((service, i) => {
      const price = getPrice(service.id);
      // console.log(price);
    });
  }, [currency]);
  // console.log("pour le test")
  // console.log(currency)

  const getCurrencySymbol = (currencyCode) => {
    switch (currencyCode) {
      case "USD":
        return "$USD";
      case "EUR":
        return "€";
      case "AUD":
        return "$AUD";
      case "CAD":
        return "$CAD";
      case "GBP":
        return "£";
      case "NZD":
        return "$NZD";
      default:
        return ""; // Si la devise n'est pas trouvée, retournez une chaîne vide
    }
  };

  return (
    <>
      <Helmet>
        <title>Prices | Meryas Home</title>
        <meta name="description" content="Increase your selling time by 85% with our virtual staging services." />
        <meta name="keywords" content="real estate virtual services, virtual home staging, virtual renovation services, real estate visualization, digital property enhancement, virtual staging for real estate, home staging online, virtual furniture placement, digital staging for property listings, sell faster with virtual staging, virtual home renovation, digital remodeling services, renovation design online, remodel your home virtually, virtual space transformation, 3D interior rendering, 3D exterior rendering, virtual 3D home tours, real estate 3D visualization, 3D home rendering services, real estate photo editing, day to dusk editing, real estate image enhancement, remove items from photos, real estate image retouching, 2d textured plans, real estate floor plan services, property floor plan design, digital floor plans for real estate, interactive floor plans online, property marketing tools, virtual listing upgrades, real estate digital solutions, online staging services, virtual open house solutions"/>        {/* Open Graph Tags */} <meta property="og:type" content="website" />
        <meta property="og:title" content="Prices | Meryas Home" />
        <meta property="og:description" content="Increase your selling time by 85% with our virtual real estate services." />
        <meta property="og:image" content="https://www.meryashome.com/static/media/RV%204_Meryas.1614038ec92569e32c1d.png" />
        <meta property="og:url" content="https://www.meryashome.com/prices" />
       
      </Helmet>
      {/* <Header /> */}
      <div
        className="banner-container"
        style={{
          background: "white",
          display: "flex",
          justifyContent: "center",
          marginTop: "50px",
        }}
      ></div>
      <div className="placeOrderMainContainer">
        <div className="placeOrderMainContent">
          <div className="placeOrderContentFirstPart"></div>
          <div className="placeOrderContentPack">
            <h1>{t("place-order-page.subtitle")}</h1>
            <div className="border max-w-[940px] w-full m-0 !border-[#06466366] " />

            <div className="placeOrderContentPackDetails">
              {Cards.map((service, i) => (
                <ServiceCard
                  key={i + 1}
                  id={i + 1}
                  title={t(`place-order-page.card${i + 1}.title`)}
                  subtitle={t(`place-order-page.card${i + 1}.subtitle`)}
                  price={getPrice(service.id)}
                  currencySymbol={getCurrencySymbol(currency)} // Ajoutez le symbole de la devise comme prop
                  image={service.cardImg.src}
                  altImg={service.cardImg.alt}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PlaceOrderPage;
