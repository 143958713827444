import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import USD from '../assets/img/flags/us.png';
import EUR from '../assets/img/flags/eur.png';
import AUD from '../assets/img/flags/aus.png';
import CAD from '../assets/img/flags/cad.png';
import GBP from '../assets/img/flags/ls.png';
import NZD from '../assets/img/flags/nzd.png';
import Other from '../assets/img/flags/world.png';
import { useWindowWidth } from "../utils/WindowWidth";

const SelectCountry = ({ setCurrency, isLandingPage }) => {
  const [selectedCurrency, setSelectedCurrency] = useState("");
  const navigate = useNavigate();
  const [dynamicMargin, setDynamicMargin] = useState("");
  const currencyLangMap = {
    "USD": "en",
    "CAD_EN": "en",  // English Canada
    "CAD_FR": "fr",  // French Canada
    "AUD": "en",
    "EUR": "en",
    "GBP": "en",
    "NZD": "en",
    "Other": "en"
  };

  const currencyData = [
    { name: "United States", code: "USD", flag: USD,currency:"USD", language:"en" },
    { name: "Canada (EN)", code: "CAD_EN", flag: CAD,currency:"CAD",language:"en"  },
    { name: "Canada (FR)", code: "CAD_FR", flag: CAD,currency:"CAD",language:"fr"  },
    { name: "Australia", code: "AUD", flag: AUD,currency:"AUD" ,language:"en" },
    { name: "Europe", code: "EUR", flag: EUR,currency:"EUR",language:"en"  },
    { name: "United Kingdom", code: "GBP", flag: GBP ,currency:"GBP",language:"en" },
    { name: "New Zealand", code: "NZD", flag: NZD,currency:"NZD",language:"en"  },
    { name: "Other", code: "Other", flag: Other ,currency:"USD",language:"en" }
  ];

  useEffect(() => {
    if (isLandingPage) {
      const savedCurrency = localStorage.getItem("currency");
      const savedLng = localStorage.getItem("lng");
      
      if (savedCurrency && savedLng) {
        setCurrency(savedCurrency);
        navigate("/");
      }
    }
  }, [navigate, setCurrency, isLandingPage]);

  const handleCurrencyChange = (currency,language) => {
    setSelectedCurrency(currency);
    localStorage.setItem("currency", currency);
    localStorage.setItem("lng", language);
    setCurrency(currency);
    

    // Force reload if not on the landing page to reflect changes
    if (!isLandingPage) {
      window.location.reload();
      // window.history.back();  // This forces the app to reload and apply the new currency and language
    } else {
      navigate("/");  // Redirect to home if it's the landing page
     
    }
  };

  const width=useWindowWidth()
  const marginTopClass = !isLandingPage
  ? width < 1020 
    ? "mt-[121px]" 
    : "mt-[121px]"  // For width less than or equal to 1320
  : "";

  return (
    <div className={`min-h-screen flex flex-col justify-center items-center h-full  `}>
      {/* Background for landing page only */}
      {(
        <div className={`absolute inset-0 bg-cover bg-center h-full ${marginTopClass  } `} style={{ backgroundImage: "url('https://cdn.shopify.com/s/files/1/0795/1083/1380/files/The_impact_of_AI_and_VR_on_3D_architectural_modeling_480x480.jpg?v=1704820143')" }}>
          <div className="absolute inset-0 bg-[#041C32] opacity-90"></div>
        </div>
      )}
{isLandingPage &&       <h1 className="text-white text-3xl z-50 text-center  tracking-widest font-medium uppercase w-full max-w-[1030px] lg:mb-24 xl-mb0 ">Meryas Home brings your vision to life with precision, creativity, and elegance in every virtual detail</h1>
}
      {isLandingPage==true && (
        <div className="absolute left-0 top-0 w-full h-full">
          <img className="w-56" src={require("../assets/img/lionLogo.png")} alt="meryas icon" />
        </div>
      )}
      
      {/* Content */}
      <div className={`relative  text-center flex flex-col items-center ${!isLandingPage ? 'mt-4' : ''}`}>
        { <img className="w-56" src={require("../assets/img/map.png")} alt="meryas icon" />}
        <h1 className={`text-2xl font-semibold  text-white mb-8 `}>
          {isLandingPage ? 'Choose Your Region' : 'Change Your Currency'}
        </h1>
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 text-center">
          {currencyData.map(({ name, code, flag, currency,language }) => (
            <div key={code} className="space-y-4">
              <button
                className="flex items-center justify-start w-full px-4 py-2 bg-white text-gray-700 text-sm hover:bg-gray-100 hover:text-[#ecb365] transition-all rounded shadow"
                onClick={() => handleCurrencyChange(currency,language)}
              >
                <img src={flag} alt={`${name} flag`} className="w-6 h-6 mr-2" />
                <span>{name}</span>
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SelectCountry;