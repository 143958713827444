import React, { useState, useRef, useEffect } from "react";
import "./FileManager.css"; // Import the CSS file
// import ImagesTyps
import { useTranslation } from "react-i18next";

const FileManager = ({ files, updateFiles, onValidate, isVirtualStaging }) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const { t } = useTranslation();
  t("images_type");
  const handleDeleteSelected = () => {
    const updatedFiles = files.filter(
      (file, index) => !selectedFiles.includes(index)
    );
    setSelectedFiles([]);
    updateFiles(updatedFiles);
  };

  const handleTypeChange = (index, selectedType) => {
    const updatedFiles = [...files];
    updatedFiles[index].exemple = selectedType;
    updateFiles(updatedFiles);
  };

  const handleItemRemovalChange = (index) => {
    const updatedFiles = [...files];
    updatedFiles[index].itemRemoval = !updatedFiles[index].itemRemoval;
    updateFiles(updatedFiles);
  };

  const handleCommentChange = (index, comment) => {
    const updatedFiles = [...files];
    updatedFiles[index].comment = comment;
    updateFiles(updatedFiles);
  };

  const fileInputRef = useRef(null);

  const handleAddFiles = () => {
    const newFiles = fileInputRef.current.files;
    if (newFiles.length > 0) {
      const newFilesArray = Array.from(newFiles).map((file) => ({
        name: file.name,
        type: "",
        itemRemoval: 0,
        comment: "",
      }));
      const updatedFiles = [...files, ...newFilesArray];
      updateFiles(updatedFiles);
      fileInputRef.current.value = null;
    }
  };

  const handleSelectChange = (index) => {
    const newSelectedFiles = selectedFiles.includes(index)
      ? selectedFiles.filter((selectedIndex) => selectedIndex !== index)
      : [...selectedFiles, index];
    setSelectedFiles(newSelectedFiles);
  };
  useEffect(() => {
    // console.log("files");
    // console.log(files);
    // Validate files whenever the files prop changes
    const validatedFiles = files.map((file) => ({
      name: file.name,
      type: file.exemple || "",
      itemRemoval: file.itemRemoval !== undefined ? 1 : 0,
      comment: file.comment || "",
      file: file,
    }));

    // Pass validatedFiles to the parent component
    onValidate(validatedFiles);
  }, [files]);

  const generateStyles = (count) => {
    const stylesList = [];
    for (let i = 1; i < count; i++) {
      stylesList.push(`type${i}`);
    }
    return stylesList;
  };

  const numberOfStyles = 32; // Vous pouvez ajuster le nombre de styles selon les styles
  const stylesList = generateStyles(numberOfStyles);

  //ici
  const generateGroupedOptions = () => {
    const groupedOptions = [];

    // Définir les groupes de types
    const typeGroups = [
      ["type1", "type2", "type3", "type4", "type5", "type6"],
      ["type7", "type8", "type9", "type10", "type11", "type12", "type13"],
      ["type14", "type15", "type16", "type17", "type18", "type19"],
      ["type20", "type21", "type22"],
      ["type23", "type24", "type25"],
      ["type26", "type27"],
      ["type28", "type29", "type30", "type31", "type32"],
    ];

    // Ajouter des options groupées avec des éléments vides
    typeGroups.forEach((group, groupIndex) => {
      groupedOptions.push(
        <optgroup key={`group-${groupIndex}`} label={` `}>
          {group.map((type, typeIndex) => (
            <option
              key={`type-${typeIndex}`}
              value={t(`images_type.types.${type}`)}
            >
              {t(`images_type.types.${type}`)}
            </option>
          ))}
        </optgroup>
      );

      // Ajouter un élément vide après chaque groupe, sauf pour le dernier groupe
      if (groupIndex < typeGroups.length - 1) {
        groupedOptions.push(
          <option key={`empty-${groupIndex}`} disabled hidden></option>
        );
      }
    });

    return groupedOptions;
  };

  // const generateGroupedOptions = () => {
  //   const groupedOptions = [];

  //   // Définir les groupes de types
  //   const typeGroups = [
  //     ["type1", "type2", "type3", "type4", "type5", "type6"],
  //     ["type7", "type8", "type9", "type10", "type11", "type12", "type13"],
  //     ["type14", "type15", "type16", "type17", "type18", "type19"],
  //     ["type20", "type21", "type22"],
  //     ["type23", "type24", "type25"],
  //     ["type26", "type27"],
  //     ["type28", "type29", "type30", "type31", "type32"],
  //   ];

  //   // Ajouter des options groupées avec des éléments vides
  //   typeGroups.forEach((group, groupIndex) => {
  //     groupedOptions.push(
  //       <optgroup key={`group-${groupIndex}`} label={` `}>
  //         {group.map((type, typeIndex) => (
  //           <option key={`type-${typeIndex}`} value={t(`images_type.types.${type}`)}>
  //             {t(`images_type.types.${type}`)}
  //           </option>
  //         ))}
  //       </optgroup>
  //     );

  //     // Ajouter un élément hr (ligne horizontale) avec une classe pour le style entre chaque groupe, sauf pour le dernier groupe
  //     if (groupIndex < typeGroups.length - 1) {
  //       groupedOptions.push(
  //         <hr key={`divider-${groupIndex}`} className="black-line" />
  //       );
  //     }
  //   });

  //   return groupedOptions;
  // };

  //ici

  return (
    <div>
      <table
        style={{
          width: "1002px",
          maxHeight: files.length > 5 ? "400px" : "767px",
          overflowY: files.length > 5 ? "scroll" : "auto",
          display: "block",
        }}
      >
        <thead style={{ background: "#F1F1F1" }}>
          <tr>
            <th style={{ width: "100px" }}>{t("fileManager.champ1")} </th>
            <th style={{ width: "200px" }}>{t("fileManager.champ2")} </th>
            <th style={{ width: "100px" }}>{t("fileManager.champ3")} </th>
            {isVirtualStaging ? (
              <th style={{ width: "150px" }}>{t("fileManager.champ4")} </th>
            ) : (
              <th style={{ width: "150px", color: "#888888" }}>
                {t("fileManager.champ4")}{" "}
              </th>
            )}
            <th style={{ width: "200px" }}>{t("fileManager.champ5")} </th>
          </tr>
        </thead>
        <tbody>
          {files.map((file, index) => (
            <tr key={index}>
              <td>
                <input
                  type="checkbox"
                  checked={selectedFiles.includes(index)}
                  onChange={() => handleSelectChange(index)}
                />
              </td>
              <td>{file.name}</td>
              <td>
                <select
                  value={file.exemple}
                  onChange={(e) => handleTypeChange(index, e.target.value)}
                >
                  <option value="" selected disabled hidden>
                    {t("images_type.default ")}
                  </option>
                  {/* ici */}
                  {generateGroupedOptions()}

                  {/* ici */}

                  {/* {stylesList.map((type, index) => (
                <option
                  key={index}
                  value={t(`images_type.types.${type}`)}
                >
                  {t(`images_type.types.${type}`)}
                </option>
              ))} */}
                </select>
              </td>
              {isVirtualStaging ? (
                <td>
                  <input
                    type="checkbox"
                    checked={file.itemRemoval}
                    onChange={() => handleItemRemovalChange(index)}
                  />
                </td>
              ) : (
                <td>
                  <input
                    type="checkbox"
                    checked={file.itemRemoval}
                    onChange={() => handleItemRemovalChange(index)}
                    disabled
                  />
                </td>
              )}

              <td>
                <input
                  type="text"
                  placeholder={t("images_type.placeHolderComment")}
                  value={file.comment}
                  onChange={(e) => handleCommentChange(index, e.target.value)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div id="bottomSection">
        <button onClick={handleDeleteSelected}>
          <span> {t("images_type.delete")}</span>
        </button>
        {/* Input and button for file selection */}
        <button onClick={() => fileInputRef.current.click()}>
          <span> {t("images_type.add")}</span>
        </button>
        <input
          type="file"
          ref={fileInputRef}
          multiple
          style={{ display: "none" }}
          onChange={handleAddFiles}
        />
      </div>
    </div>
  );
};
export default FileManager;
